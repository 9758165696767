import { Outlet } from 'react-router-dom';

import * as style from '@/apps/login/layouts/main/styles';
import Intro from '@/assets/videos/intro.mp4';

export function Main() {
  return (
    <div className={style.container()}>
      <section className={style.banner()}>
        <video className={style.bannerImage()} loop autoPlay muted>
          <source src={Intro} type="video/mp4" />
        </video>
      </section>

      <Outlet />
    </div>
  );
}
