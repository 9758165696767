import { Suspense } from 'react';
import { MagicMotion } from 'react-magic-motion';
import { Outlet } from 'react-router-dom';

import { LoadingFallback } from '@/common/components/LoadingFallback';

import { Sidebar } from './components/Sidebar';
import { container, content } from './styles';

export function Root() {
  return (
    <div className={container()}>
      <MagicMotion transition={{ type: 'tween' }}>
        <Sidebar />
      </MagicMotion>

      <div className={content()}>
        <Suspense fallback={<LoadingFallback />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
}
