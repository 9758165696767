import { cva } from 'class-variance-authority';

export const button = cva(
  [
    'inline-flex',
    'px-3',
    'items-center',
    'border',
    'border-transparent',
    'justify-center',
    'font-inter',
    'hover:transition-all',
    'shadow-default',
    'select-none',
  ],
  {
    variants: {
      primary: {
        true: [
          'bg-brand-pure',
          'text-white',
          'font-semibold',
          'hover:bg-brand-500',
        ],
      },
      secondary: {
        true: [
          'bg-white',
          'text-sm',
          'font-regular',
          'text-brand-800',
          'hover:bg-brand-100',
          'border',
          'border-neutral-300',
          'drop-shadow',
        ],
      },
      success: {
        true: [
          'bg-sustainability-green-800',
          'text-neutral-800',
          'font-semibold',
          'hover:bg-sustainability-green-900',
        ],
      },
      danger: {
        true: [
          'bg-transparent',
          'text-neutral-700',
          'font-semibold',
          'hover:bg-neutral-100',
          'drop-shadow',
        ],
      },
      dangerSoft: {
        true: [
          'bg-driver-centricity-50',
          'text-driver-centricity-800',
          'font-semibold',
          'hover:bg-driver-centricity-300',
          'border',
          'border-driver-centricity-300',
        ],
      },

      small: {
        true: ['h-10', 'text-sm', 'gap-1', 'rounded'],
      },
      medium: {
        true: ['h-12', 'text-base', 'gap-2', 'rounded-md'],
      },
      large: {
        true: ['h-11', 'w-32', 'text-base', 'gap-2', 'rounded-md'],
      },
      isDisabled: {
        true: ['opacity-50', 'cursor-not-allowed'],
        false: ['cursor-pointer'],
      },
    },
  },
);

export const containerIcon = cva(['flex', 'items-center', 'justify-center']);

export const icon = cva(['h-5 w-5 text-brand-800']);

export const loading = cva(
  [
    'animate-spin',
    'inline-block',
    'w-4',
    'h-4',
    'border-[3px]',
    'border-current',
    'border-t-transparent',
    'text-white',
    'rounded-full',
  ],
  {
    variants: {
      isLoading: {
        true: ['inline-block'],
        false: ['hidden'],
      },
    },
  },
);
