import { cva } from 'class-variance-authority';

export const container = cva([
  'select-none',
  'font-inter',
  'w-full',
  'pb-5',
  'flex',
  'justify-between',
  'items-start',
]);

export const heading = cva(['flex', 'flex-col', 'gap-1']);

export const title = cva(['text-3xl', 'font-semibold', 'text-brand-800']);

export const breadcrumbContainer = cva(['flex', 'gap-5', 'items-center']);

export const actions = cva(['flex', 'justify-center', 'items-center', 'gap-4']);

export const actionsWrapper = cva(['flex', 'gap-4', 'items-end']);
