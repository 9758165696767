import { useFlagsmith } from 'flagsmith/react';
import { Link, useLocation } from 'react-router-dom';

import { SettingsNavigation } from '@/common/modules/settings/config/settingsNavigation';
import { ISettingsNavigation } from '@/common/modules/settings/types/SettingsNavigation';

import * as style from './styles';

export function Sidebar() {
  const location = useLocation();

  const { hasFeature } = useFlagsmith();

  return (
    <nav className={style.container()}>
      <ul className={style.wrapperLinks()}>
        {SettingsNavigation()?.map((navItem: ISettingsNavigation) => {
          if (navItem.flag && !hasFeature(navItem.flag)) return null;

          return (
            <li key={navItem?.key} className={style.navItem()}>
              <Link
                to={navItem?.link}
                className={style.link({
                  active: location.pathname.includes(navItem?.link),
                })}
              >
                <span
                  className={style.text({
                    active: location.pathname.includes(navItem?.link),
                  })}
                >
                  {navItem?.title}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
