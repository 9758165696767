/* eslint-disable @typescript-eslint/no-explicit-any */
export const APP_STORAGE_SUFFIX = '@nexpro:';

type StorageOptions = {
  parse?: boolean;
};

export function setItemLocal(suffix: string, value: string) {
  localStorage.setItem(APP_STORAGE_SUFFIX + suffix, value);
}

export function getItemLocal<T>(
  suffix: string,
  options?: StorageOptions,
): T | null {
  const { parse = true } = options || {};

  const value = localStorage.getItem(APP_STORAGE_SUFFIX + suffix);

  if (value && parse) {
    return JSON.parse(value) as T;
  }

  if (value) {
    return value as T;
  }

  return null;
}

export function removeItemLocal(suffix: string) {
  localStorage.removeItem(APP_STORAGE_SUFFIX + suffix);
}

export function clearLocal() {
  localStorage.clear();
}
