import { useQuery } from '@tanstack/react-query';

import { getUserInfo } from '../services/user';

export function useGetUserInfo() {
  const { data, isLoading, isFetching, refetch } = useQuery(
    ['getUserInfo'],
    async () => getUserInfo(),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: 1000 * 60 * 60,
    },
  );

  return {
    user: data?.data,
    isLoading: isLoading && isFetching,
    refetch,
  };
}
