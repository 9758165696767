/* eslint-disable react/button-has-type */
import { LucideIcon } from 'lucide-react';
import { forwardRef } from 'react';

import { cn } from '@/common/utils/shadcn';

import * as style from './styles';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'dangerSoft';

export type ButtonSize = 'small' | 'medium' | 'large';

export type IconAlignment = 'start' | 'end';

export interface IButton extends React.ComponentPropsWithRef<'button'> {
  text?: string;
  Icon?: LucideIcon;
  iconClassName?: string;
  iconAlignment?: IconAlignment;
  variant?: ButtonVariant;
  size?: ButtonSize;
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      text,
      Icon,
      iconClassName,
      iconAlignment = 'start',
      variant = 'primary',
      size = 'medium',
      isLoading = false,
      disabled,
      className,
      type = 'button',
      ...rest
    }: IButton,
    ref,
  ) => {
    return (
      <button
        {...rest}
        type={type}
        ref={ref}
        className={cn(
          style.button({
            [variant]: true,
            [size]: true,
            isDisabled: isLoading || disabled,
          }),
          className,
        )}
        disabled={isLoading || disabled}
      >
        <span
          className={style.loading({
            isLoading,
          })}
          role="status"
          aria-label="loading"
        />

        {Icon && iconAlignment === 'start' && !isLoading && (
          <div className={style.containerIcon()}>
            <Icon className={`${style.icon()} ${iconClassName}`} />
          </div>
        )}

        {text && !isLoading && <span>{text}</span>}

        {Icon && iconAlignment === 'end' && !isLoading && (
          <div className={style.containerIcon()}>
            <Icon className={`${style.icon()} ${iconClassName}`} />
          </div>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
