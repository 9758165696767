import { Search } from 'lucide-react';
import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Breadcrumb,
  IBreadcrumb,
} from '@/apps/backoffice/common/components/Header/components/Breadcrumb';
import { Badge } from '@/common/components/Badge';

import * as style from './styles';

interface IFiltersHeaderProps {
  title: string;
  breadcrumbs?: IBreadcrumb[];
  children?: ReactNode;
  filters?: string[];
  onRemoveFilter?: (filter: string) => void;
  onClearFreeSearch: () => void;
  onClearFilters?: () => void;
}

export function FiltersHeader({
  title,
  breadcrumbs,
  children,
  filters,
  onRemoveFilter,
  onClearFreeSearch,
  onClearFilters,
}: IFiltersHeaderProps) {
  const hookForm = useFormContext();

  const freeSearch: string = hookForm.watch('freeSearch') || '';

  const handleClearFreeSearch = () => {
    hookForm.setValue('freeSearch', '');
    onClearFreeSearch();
  };

  const handleClearFilters = () => {
    hookForm.reset();
    if (onClearFilters) onClearFilters();
  };

  return (
    <header className={style.container()}>
      <div className={style.heading()}>
        <h1 className={style.title()}>{title}</h1>

        <div className={style.actionsWrapper()}>{children}</div>
      </div>

      <div className={style.headerFooter()}>
        <div className={style.breadCrumbs()}>
          {breadcrumbs?.map((breadcrumb, index) => (
            <Breadcrumb
              key={breadcrumb?.title}
              isLink={
                Boolean(breadcrumb?.link) && index < breadcrumbs?.length - 1
              }
              isActive={index !== 0 && index === breadcrumbs?.length - 1}
              isLast={index === breadcrumbs?.length - 1}
              data={breadcrumb}
            />
          ))}
        </div>

        <div className={style.badgesContainer()}>
          {freeSearch && (
            <Badge
              title={freeSearch}
              LeadingIcon={<Search size={12} />}
              onRemove={handleClearFreeSearch}
            />
          )}

          {filters?.map(filter => (
            <Badge
              key={filter}
              title={filter}
              onRemove={() => onRemoveFilter && onRemoveFilter(filter)}
            />
          ))}

          {freeSearch || (filters && filters?.length > 0) ? (
            <Badge
              title="Limpar filtros"
              onRemove={handleClearFilters}
              type="danger"
            />
          ) : null}
        </div>
      </div>
    </header>
  );
}
