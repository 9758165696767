import { useTranslation } from 'react-i18next';

export function useRoleTranslation(): Record<string, string> {
  const { t } = useTranslation('enums/role');

  return {
    ROOT: t('root'),
    OWNER: t('owner'),
    ADMIN: t('admin'),
    MANAGER: t('manager'),
    OPERATOR: t('operator'),
    SUPPORT: t('support'),
    VIEWER: t('viewer'),
  };
}
