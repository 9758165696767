import { cva } from 'class-variance-authority';

export const container = cva(
  [
    'block',
    'bg-white',
    'border-r',
    'border-neutral-200',
    'py-8',
    'overflow-y-auto',
    'scrollbar-y',
    'select-none',
    'shadow-2xl',
    'font-inter',
    'text-sm',
    'font-regular',
    'min-w-fit',
  ],
  {
    variants: {
      isRetracted: {
        true: ['w-20', 'px-0'],
        false: ['max-w-[260px]', 'px-5'],
      },
    },
  },
);

export const wrapperSections = cva([
  'flex',
  'flex-col',
  'h-full',
  'justify-between',
  'w-full',
]);

export const wrapperLogo = cva(['flex', 'w-full', 'gap-2', 'mt-1'], {
  variants: {
    isRetracted: {
      false: ['justify-between'],
      true: ['justify-center'],
    },
  },
  defaultVariants: {
    isRetracted: false,
  },
});

export const logo = cva(['w-40', 'cursor-pointer'], {
  variants: {
    isRetracted: {
      true: ['hidden'],
    },
  },
});

export const wrapperArrow = cva([
  'flex',
  'items-center',
  'justify-center',
  'w-8',
  'h-8',
  'bg-brand-pure',
  'rounded-md',
  'shadow-sm',
  'cursor-pointer',
]);

export const menuSection = cva(['flex', 'w-full', 'flex-col']);

export const menuContainer = cva([
  'pt-12',
  'w-full',
  'flex',
  'flex-col',
  'flex-wrap',
]);

export const menuList = cva(['space-y-2', 'truncate', 'hs-accordion-group'], {
  variants: {
    isRetracted: {
      true: ['flex', 'flex-col', 'items-center'],
    },
  },
});

export const link = cva(
  [
    'flex',
    'items-center',
    'gap-x-3.5',
    'py-2',
    'px-2.5',
    'text-sm',
    'font-inter',
    'rounded-md',
    'w-full',
    'h-full',
    'truncate',
  ],
  {
    variants: {
      active: {
        true: ['bg-brand-400', 'text-brand-50'],
        false: [
          'text-neutral-700',
          'hover:bg-brand-50',
          'hover:text-brand-400',
        ],
      },
      danger: {
        true: [
          'hover:bg-driver-centricity-50',
          'hover:text-driver-centricity-600',
        ],
      },
      noAccess: {
        true: ['cursor-not-allowed', 'hover:bg-transparent'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);

export const icon = cva([''], {
  variants: {
    isActive: {
      true: ['text-white'],
      false: ['text-brand-pure'],
    },
    noAccess: {
      true: ['text-neutral-400'],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});
