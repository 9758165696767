import Cookies from 'js-cookie';
import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';

import { useLogin } from '@/apps/login/hooks/useLogin';
import SidebarSkeleton from '@/common/components/SidebarSkeleton';
import { useAppStore } from '@/common/hooks/AppStore';
import { httpClient } from '@/config/httpClient';

export function AppRoutes() {
  const { app, matchApp } = useAppStore();
  const { loadCredentials } = useLogin();

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const sessionState = Cookies.get('sessionState');

    if (!accessToken || !refreshToken || !sessionState) return;

    httpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;

    loadCredentials();
  }, [loadCredentials]);
  return (
    <Suspense fallback={<SidebarSkeleton />}>
      <RouterProvider router={matchApp(app)} />
    </Suspense>
  );
}
