import { zodResolver } from '@hookform/resolvers/zod';
import { Filter } from 'lucide-react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '@/apps/backoffice/common/components/Header';
import Button from '@/common/components/Button';
import { FiltersHeader } from '@/common/components/FiltersHeader';
import { UserRoleEnum } from '@/common/types/Role';
import { links } from '@/common/utils/links';

import { useFiltersUsersTable } from '../../hooks/useFiltersUsersTable';
import {
  UsersFilterForm,
  UsersFilterSchema,
} from '../../schemas/UsersFilterSchema';

import { Sidebar } from './components/Sidebar';
import * as style from './styles';

export function Settings() {
  const { t } = useTranslation(['common', 'settings', 'enums/role']);

  const { pathname } = useLocation();

  const { updateRoles, updateFreeSearch, clearFilters, roles } =
    useFiltersUsersTable();

  const drawerFiltersUsersHookForm = useForm<UsersFilterForm>({
    resolver: zodResolver(UsersFilterSchema()),
    shouldFocusError: true,
    defaultValues: {
      freeSearch: '',
      roles: undefined,
    },
  });

  const onRemoveUsersFilter = (filter: string) => {
    if (!roles) return;

    const filterToUserRoleEnum = {
      [t(`enums/role:${UserRoleEnum.ROOT.toLowerCase()}`)]: UserRoleEnum.ROOT,
      [t(`enums/role:${UserRoleEnum.OWNER.toLowerCase()}`)]: UserRoleEnum.OWNER,
      [t(`enums/role:${UserRoleEnum.ADMIN.toLowerCase()}`)]: UserRoleEnum.ADMIN,
      [t(`enums/role:${UserRoleEnum.MANAGER.toLowerCase()}`)]:
        UserRoleEnum.MANAGER,
      [t(`enums/role:${UserRoleEnum.OPERATOR.toLowerCase()}`)]:
        UserRoleEnum.OPERATOR,
      [t(`enums/role:${UserRoleEnum.SUPPORT.toLowerCase()}`)]:
        UserRoleEnum.SUPPORT,
      [t(`enums/role:${UserRoleEnum.VIEWER.toLowerCase()}`)]:
        UserRoleEnum.VIEWER,
    };

    const newFilters = roles?.filter(f => f !== filterToUserRoleEnum[filter]);

    const selectOptionRoles = newFilters?.map(role => ({
      label: t(`enums/role:${role.toLowerCase()}`),
      value: role,
    }));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    drawerFiltersUsersHookForm.setValue('roles', selectOptionRoles);

    updateRoles(newFilters);
  };

  const usersFiltersBadges = roles?.map(role =>
    t(`enums/role:${role.toLowerCase()}`),
  );

  const HEADER_COMPONENT_BY_PATHNAME = {
    [links.settings.profile]: (
      <Header
        title={t('settings/profile:myProfile')}
        breadcrumbs={[
          { title: t('settings/profile:settings') },
          {
            title: t('settings/profile:myProfile'),
            link: links.settings.profile,
          },
        ]}
      />
    ),
    [links.settings.account]: (
      <Header
        title={t('settings/account:password')}
        breadcrumbs={[
          { title: t('settings/account:settings') },
          {
            title: t('settings/account:password'),
            link: links.settings.account,
          },
        ]}
      />
    ),
    [links.settings.company]: (
      <Header
        title={t('settings/company:company')}
        breadcrumbs={[
          { title: t('settings/company:settings') },
          {
            title: t('settings/company:company'),
            link: links.settings.company,
          },
        ]}
      />
    ),
    [links.settings.modules]: (
      <Header
        title={t('settings/modules:modules')}
        breadcrumbs={[
          { title: t('settings/modules:settings') },
          {
            title: t('settings/modules:modules'),
            link: links.settings.company,
          },
        ]}
      />
    ),
    [links.settings.users]: (
      <FiltersHeader
        title={t('settings/users:users')}
        breadcrumbs={[
          { title: t('settings/users:settings') },
          {
            title: t('settings/users:users'),
            link: links.settings.users,
          },
        ]}
        onClearFilters={() => clearFilters()}
        onClearFreeSearch={() => updateFreeSearch('')}
        filters={usersFiltersBadges}
        onRemoveFilter={onRemoveUsersFilter}
      >
        <Button
          variant="secondary"
          Icon={Filter}
          text={t('common:filters')}
          iconClassName="text-brand-pure"
          iconAlignment="start"
          data-hs-overlay="#drawer-users-filters"
          size="small"
        />
      </FiltersHeader>
    ),
    [links.settings.apiData]: (
      <Header
        title={t('settings/apiData:apiData')}
        breadcrumbs={[
          { title: t('settings/apiData:settings') },
          {
            title: t('settings/apiData:apiData'),
            link: links.settings.apiData,
          },
        ]}
      />
    ),
  };

  return (
    <FormProvider {...drawerFiltersUsersHookForm}>
      <div className={style.container()}>
        <Helmet>
          <title>{`${t('common:settings')} - NEXPRO by IVECO`}</title>
        </Helmet>

        {
          HEADER_COMPONENT_BY_PATHNAME[
            pathname as keyof typeof HEADER_COMPONENT_BY_PATHNAME
          ]
        }

        <div className={style.content()}>
          <Sidebar />

          <Outlet />
        </div>
      </div>
    </FormProvider>
  );
}
