import { cva } from 'class-variance-authority';

export const container = cva(['w-full', 'max-w-[248px]']);

export const wrapperLinks = cva(['w-full', 'flex', 'flex-col', 'gap-3']);

export const navItem = cva([
  'w-full',
  'flex',
  'items-center',
  'justify-center',
]);

export const link = cva(
  ['w-full', 'py-3', 'px-4', 'rounded-md', 'hover:bg-brand-50'],
  {
    variants: {
      active: {
        true: ['bg-brand-50'],
      },
    },
  },
);

export const text = cva(
  ['font-inter', 'text-sm', 'font-semibold', 'transition-colors'],
  {
    variants: {
      active: {
        true: ['text-brand-800'],
        false: ['text-neutral-800'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);
