import bgLogo from '@/assets/images/loading-logo.png';

import * as style from './styles';

export function LoadingFallback() {
  return (
    <div className={style.container()}>
      <img className={style.image()} src={bgLogo} alt="..." />
    </div>
  );
}
