import { ToastOptions, toast } from 'react-toastify';

import { Toast } from '../components/Toast';

export function useToast() {
  const info = (
    message: string,
    description?: string | null,
    options?: ToastOptions,
  ) => {
    toast.info(
      ({ closeToast }) => (
        <Toast
          message={message}
          description={description}
          type="info"
          handleClose={closeToast}
        />
      ),
      { containerId: 'default', ...options },
    );
  };

  const success = (
    message: string,
    description?: string | null,
    options?: ToastOptions,
  ) => {
    toast.success(
      ({ closeToast }) => (
        <Toast
          message={message}
          description={description}
          type="success"
          handleClose={closeToast}
        />
      ),
      { containerId: 'default', ...options },
    );
  };

  const warn = (
    message: string,
    description?: string | null,
    options?: ToastOptions,
  ) => {
    toast.warn(
      ({ closeToast }) => (
        <Toast
          message={message}
          description={description}
          type="warning"
          handleClose={closeToast}
        />
      ),
      { containerId: 'default', ...options },
    );
  };

  const error = (
    message: string,
    description?: string | null,
    options?: ToastOptions,
  ) => {
    toast.error(
      ({ closeToast }) => (
        <Toast
          message={message}
          description={description}
          type="error"
          handleClose={closeToast}
        />
      ),
      { containerId: 'default', ...options },
    );
  };

  return {
    toast: {
      info,
      success,
      warn,
      error,
    },
  };
}
