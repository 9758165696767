import { create } from 'zustand';

import { BackofficeApp } from '@/apps/backoffice';
import { BackofficeAppNavigation } from '@/apps/backoffice/config/backofficeAppNavigation';
import { LoginApp } from '@/apps/login';

import { IAppNavigation } from '../types/AppNavigation';
import { getItemLocal } from '../utils/localStorage';

export enum EnumAPPS {
  LOGIN = 'LOGIN',
  API = 'API',
}

interface IAppStore {
  app: EnumAPPS;
  appNavigation: IAppNavigation[];
  changeApp: (app: EnumAPPS) => void;
  matchApp: (app: EnumAPPS) => typeof BackofficeApp;
}

const store = create<IAppStore>(set => {
  const loadedApp = getItemLocal<EnumAPPS>('app', { parse: false });

  const matchAppNavigation = (app: EnumAPPS): IAppNavigation[] => {
    switch (app) {
      case EnumAPPS.API:
        return BackofficeAppNavigation();

      default:
        return [];
    }
  };

  return {
    app: loadedApp || EnumAPPS.LOGIN,
    appNavigation: matchAppNavigation(loadedApp || EnumAPPS.API),

    matchApp(app: EnumAPPS) {
      switch (app) {
        case EnumAPPS.API:
          return BackofficeApp;

        default:
          return LoginApp;
      }
    },
    changeApp(newApp: EnumAPPS) {
      set(() => ({
        app: newApp,
        appNavigation: matchAppNavigation(newApp),
      }));
    },
  };
});

export const useAppStore = () => {
  return store();
};
