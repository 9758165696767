import { cva } from 'class-variance-authority';

export const sidebar = cva([
  'block',
  'bg-white',
  'border-r',
  'border-gray-200',
  'py-8',
  'select-none',
  'shadow-2xl',
  'min-w-fit',
  'max-w-[240px]',
  'h-screen',
  'px-5',
  'animate-pulse',
]);
export const wrapperSections = cva([
  'flex',
  'flex-col',
  'w-full',
  'h-full',
  'justify-between',
]);

export const wrapperLogo = cva([
  'flex',
  'w-full',
  'gap-2',
  'justify-center',
  'bg-gray-200',
  'rounded',
  'h-10',
  'mb-12',
  'p-0.5',
]);

export const logo = cva(['w-20', 'cursor-pointer']);

export const buttonSkeleton = cva(['h-10', 'bg-gray-200', 'rounded', 'mb-2.5']);

export const horizontalDivider = cva([
  'bg-gray-200',
  'mb-4',
  'w-full',
  'h-[1px]',
]);

export const avatarWrapper = cva([
  'flex',
  'items-center',
  'my-6',
  'border-sky-100',
  'ml-2',
]);

export const avatar = cva([
  'w-[26px]',
  'h-[26px]',
  'rounded-full',
  'bg-gray-200',
]);

export const textWrapper = cva(['ml-3', 'pt-2']);

export const skeletonText = cva([
  'w-20',
  'h-2.5',
  'bg-gray-200',
  'rounded-full',
  'mb-2',
]);
