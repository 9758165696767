import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

import * as style from './styles';

type CheckboxProps = {
  name: string;
  label: string;
  extraClassNames?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({
  name,
  label,
  extraClassNames,
  ...props
}: CheckboxProps) {
  const { register } = useFormContext();

  return (
    <div className="flex">
      <input
        type="checkbox"
        className={style.checkbox()}
        id={`hs-checked-checkbox-${name}`}
        {...register(name)}
        {...props}
      />
      <label
        htmlFor={`hs-checked-checkbox-${name}`}
        // TODO use cn
        className={`${style.label()} ${extraClassNames}`}
      >
        {label}
      </label>
    </div>
  );
}
