import { cva } from 'class-variance-authority';

export const container = cva([
  'flex',
  'items-center',
  'justify-center',
  'h-screen',
  'w-full',

]);

export const image = cva(['animate-heartbeat', 'max-w-2xl']);
