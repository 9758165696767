import { useMutation } from '@tanstack/react-query';
import flagsmith from 'flagsmith';
import Cookies from 'js-cookie';
import { z } from 'zod';

import { signInService } from '@/apps/login/services/signIn';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { removeItemLocal } from '@/common/utils/localStorage';
import { httpClient } from '@/config/httpClient';

const SignInSchema = z.object({
  username: z.string().nonempty(),
  password: z.string().nonempty(),
  reCaptcha: z.boolean().refine(value => value === true),
  rememberData: z.boolean(),
  reCaptchaToken: z.string().optional(),
});

export type SignInForm = z.infer<typeof SignInSchema>;

export type GetTokenCredentials = {
  username: string;
  password: string;
  grant_type: string;
  reCaptcha: string;
};

export function useLogin() {
  const { mutateAsync: mutateSignIn, isLoading: isLoadingSignIn } =
    useMutation(signInService);

  const { changeApp } = useAppStore();

  const signIn = async (data: SignInForm) => {
    const credentials: GetTokenCredentials = {
      username: data.username,
      password: data.password,
      reCaptcha: data.reCaptchaToken!,
      grant_type: 'password',
    };

    const response = await mutateSignIn(credentials);

    const {
      access_token,
      expires_in,
      refresh_token,
      refresh_expires_in,
      session_state,
    } = response.data;

    httpClient.defaults.headers.Authorization = `Bearer ${access_token}`;

    if (!data.rememberData) return;

    Cookies.set('accessToken', access_token, { expires: expires_in });
    Cookies.set('refreshToken', refresh_token, {
      expires: refresh_expires_in,
    });
    Cookies.set('sessionState', session_state);
  };

  const signOut = async () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    Cookies.remove('sessionState');
    removeItemLocal('app');
    await flagsmith.logout();

    httpClient.defaults.headers.Authorization = '';

    changeApp(EnumAPPS.LOGIN);
    window.location.href = '/';
  };

  const loadCredentials = () => {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const sessionState = Cookies.get('sessionState');

    if (!accessToken || !refreshToken || !sessionState) {
      changeApp(EnumAPPS.LOGIN);
      return;
    }

    httpClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
  };

  return { signIn, isLoadingSignIn, SignInSchema, signOut, loadCredentials };
}
