import { lazy } from 'react';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import { Main } from '@/apps/login/layouts/main';
import { links } from '@/common/utils/links';

import { SignIn } from '../pages/SignIn';

const PrivacyPolicy = lazy(() => import('@/common/components/PrivacyPolicy'));
const ActivateAccount = lazy(() => import('../pages/ActivateAccount'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));

const LoginRoutes: RouteObject[] = [
  {
    element: <Main />,
    children: [
      {
        path: links.login.main,
        element: <SignIn />,
      },
      {
        path: links.login.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: links.login.resetPassword,
        element: <ResetPassword />,
      },
      {
        path: links.login.activateAccount,
        element: <ActivateAccount />,
      },
    ],
    // TODO: Create ErrorBoundaryApi component
    // errorElement: <ErrorBoundaryApi />
  },
  {
    path: '*',
    element: <Main />,
    children: [
      {
        path: '*',
        index: true,
        element: <SignIn />,
      },
    ],
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
];

export const LoginApp = createBrowserRouter(LoginRoutes);
