import { ArrowLeftToLine } from 'lucide-react';
import { useState } from 'react';

import tinyLogoImg from '@/assets/icons/logo.svg';
import NexproLogoImg from '@/assets/icons/nexpro.svg';
import { useAppStore } from '@/common/hooks/AppStore';

import { ConfigSection } from './components/ConfigSection';
import { NavItem } from './components/NavItem';
import * as style from './styles';

export function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { appNavigation } = useAppStore();

  const handleToggleCollapse = () => {
    setIsCollapsed(state => !state);
  };

  const handleUnCollapse = () => {
    setIsCollapsed(false);
  };

  return (
    <div className={style.container({ isRetracted: isCollapsed })}>
      <div key="exclude" className={style.wrapperSections()}>
        <div className={style.menuSection()}>
          <div className={style.wrapperLogo({ isRetracted: isCollapsed })}>
            <img
              src={NexproLogoImg}
              alt="Iveco"
              className={style.logo({ isRetracted: isCollapsed })}
            />

            {isCollapsed ? (
              <button type="button" onClick={handleToggleCollapse}>
                <img src={tinyLogoImg} alt="Iveco" />
              </button>
            ) : (
              <button
                type="button"
                className={style.wrapperArrow()}
                onClick={handleToggleCollapse}
              >
                <ArrowLeftToLine size={16} color="white" />
              </button>
            )}
          </div>

          <nav className={style.menuContainer()}>
            <ul className={style.menuList({ isRetracted: isCollapsed })}>
              {appNavigation.map(menuItem => (
                <NavItem
                  data={menuItem}
                  key={menuItem.key}
                  isCollapsed={isCollapsed}
                  onClickSubMenu={handleUnCollapse}
                />
              ))}
            </ul>
          </nav>
        </div>

        <ConfigSection isCollapsed={isCollapsed} />
      </div>
    </div>
  );
}
