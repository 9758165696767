import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const Panel = lazy(() => import('./pages/panel'));

export const PanelRoutes: RouteObject[] = [
  {
    index: true,
    element: <Panel />,
  },
];
