import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';

import { LoadingFallback } from '../LoadingFallback';

import * as style from './styles';

export default function SidebarSkeleton() {
  const { app } = useAppStore();

  if (app === EnumAPPS.LOGIN) return <LoadingFallback />;

  return (
    <div className={style.sidebar()}>
      <div className={style.wrapperSections()}>
        <div>
          <div className={style.wrapperLogo()} />
          <div className={style.buttonSkeleton()} />
          <div className={style.buttonSkeleton()} />
        </div>
        <div>
          <div className={style.horizontalDivider()} />
          <div className={style.avatarWrapper()}>
            <div className={style.avatar()} />
            <div className={style.textWrapper()}>
              <div className={style.skeletonText()} />
              <div className={style.skeletonText()} />
            </div>
          </div>
          <div className={style.buttonSkeleton()} />
          <div className={style.buttonSkeleton()} />
        </div>
      </div>
    </div>
  );
}
