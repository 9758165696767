import { cva } from 'class-variance-authority';

export const configSection = cva(['flex', 'w-full', 'flex-col']);

export const menuContainer = cva([
  'pt-12',
  'w-full',
  'flex',
  'flex-col',
  'flex-wrap',
]);

export const horizontalDivider = cva(['bg-neutral-200', 'mb-4'], {
  variants: {
    isRetracted: {
      true: ['mx-auto', 'w-8', 'h-[2px]'],
      false: ['w-full', 'h-[1px]'],
    },
  },
});

export const menuList = cva(['space-y-2', 'truncate', 'hs-accordion-group'], {
  variants: {
    isRetracted: {
      true: ['flex', 'flex-col', 'items-center'],
    },
  },
});

export const link = cva(
  [
    'flex',
    'items-center',
    'gap-x-3.5',
    'p-3.5',
    'text-sm',
    'font-inter',
    'rounded-[4px]',
    'w-full',
    'h-full',
    'truncate',
  ],
  {
    variants: {
      active: {
        true: [
          'bg-gradient-to-b from-inovation-800 from-[-50%] to-brand-400 to-100%',
          'text-brand-50',
        ],
        false: [
          'text-neutral-700',
          'hover:bg-brand-50',
          'hover:text-brand-400',
        ],
      },
      danger: {
        true: [
          'hover:bg-driver-centricity-50',
          'hover:text-driver-centricity-600',
        ],
      },
      noAccess: {
        true: ['cursor-not-allowed', 'hover:bg-transparent'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);

export const icon = cva([''], {
  variants: {
    isActive: {
      true: ['text-white'],
      false: ['text-brand-pure'],
    },
    noAccess: {
      true: ['text-neutral-400'],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export const profileContainer = cva(
  [
    'flex',
    'border',
    'rounded-lg',
    'border-neutral-200',
    'shadow-default',
    'gap-3',
    'items-center',
  ],
  {
    variants: {
      isRetracted: {
        true: ['px-[7px]', 'h-[53px]'],
        false: ['px-3', 'py-2'],
      },
    },
  },
);

export const description = cva(['flex', 'flex-col', 'max-w-[8rem]']);

export const name = cva([
  'font-inter',
  'text-sm',
  'text-neutral-700',
  'font-semibold',
  'leading-tight',
  'text-ellipsis',
  'whitespace-nowrap',
  'overflow-hidden',
]);

export const role = cva([
  'font-inter',
  'text-sm',
  'text-neutral-600',
  'font-light',
  'leading-tight',
]);
