import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { UserRoleEnum } from '@/common/types/Role';

export function UsersFilterSchema() {
  const { t } = useTranslation('common');

  return z
    .object({
      freeSearch: z.string().optional(),
      roles: z
        .array(
          z
            .object({
              value: z.string().nonempty(),
            })
            .transform(val => val.value as UserRoleEnum),
        )
        .optional(),
    })
    .refine(
      ({ freeSearch, roles }) => {
        if (!freeSearch && !roles) {
          return false;
        }

        return true;
      },
      { message: t('atLeastOneFilter'), path: ['freeSearch'] },
    );
}

export type UsersFilterForm = z.infer<ReturnType<typeof UsersFilterSchema>>;
