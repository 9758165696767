import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

export type TextInputVariants = VariantProps<typeof Input>;

export const Label = cva(['inline-block', 'text-sm', 'font-medium', 'mb-2']);

export const Input = cva(
  [
    'block',
    'w-full',
    'rounded-md',
    'border-2',
    'border-gray-200',
    'focus:border-blue-500',
    'focus:ring-blue-500',
    'text-sm',
    'font-normal',
    'placeholder-neutral-500',
    'font-medium',
  ],
  {
    variants: {
      hasError: {
        true: ['border-red-500', 'focus:border-red-500', 'focus:ring-red-500'],
      },
      isSuccessful: {
        true: [
          'border-teal-500',
          'focus:border-teal-500',
          'focus:ring-teal-500',
        ],
      },
      hasLeadingIcon: {
        true: ['pl-11 sm:pl-11'],
      },
      hasTrailingIcon: {
        true: ['pr-11 sm:pr-11'],
      },
      size: {
        sm: ['py-2', 'px-3'],
        md: ['py-3', 'px-4'],
        lg: ['py-3', 'px-4', 'sm:p-5'],
      },
      isDisabled: {
        true: ['cursor-text', 'bg-gray-100'],
      },
    },
    defaultVariants: {
      hasError: false,
      isSuccessful: false,
      hasLeadingIcon: false,
      hasTrailingIcon: false,
      size: 'md',
    },
  },
);

export const ContainerIcon = cva(['absolute', 'flex', 'items-center', 'z-20'], {
  variants: {
    leading: {
      true: ['left-0', 'pl-4', 'pointer-events-none'],
      false: ['right-0', 'pr-4', 'cursor-pointer'],
    },
    isLabelled: {
      true: ['inset-y-0', 'top-7'],
      false: ['inset-y-0', 'bottom-1'],
    },
  },
});

export const ErrorMessage = cva(['text-sm text-red-600 mt-2']);
