import { LogOut, Settings } from 'lucide-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { useLogin } from '@/apps/login/hooks/useLogin';
import Avatar from '@/common/components/Avatar';
import { Tooltip } from '@/common/components/Tooltip';
import { useGetUserInfo } from '@/common/hooks/useGetUserInfo';
import { useRoleTranslation } from '@/common/hooks/useRoleTranslation';
import { useUserStore } from '@/common/hooks/UserStore';
import { links } from '@/common/utils/links';

import * as style from './styles';

interface IConfigSection {
  isCollapsed: boolean;
}

export function ConfigSection({ isCollapsed }: IConfigSection) {
  const location = useLocation();
  const { signOut } = useLogin();

  const { t } = useTranslation('sidebar/fixedMenus');

  const { user } = useGetUserInfo();
  const { role, person, storeAccountUser, storeCompanyUser } = useUserStore();

  const roleTranslation = useRoleTranslation();
  const roleTranslated = roleTranslation[role || ''];

  useEffect(() => {
    if (user?.UserAccount) storeAccountUser(user);
    if (user?.UserCompany) storeCompanyUser(user);
  }, [user, storeAccountUser, storeCompanyUser]);

  return (
    <div className={style.configSection()}>
      <nav className={style.menuContainer()}>
        <div
          className={style.horizontalDivider({ isRetracted: isCollapsed })}
        />

        <ul className={style.menuList({ isRetracted: isCollapsed })}>
          {user && (
            <li>
              <div
                className={style.profileContainer({
                  isRetracted: isCollapsed,
                })}
              >
                <Avatar
                  url={user?.avatarUrl}
                  name={person?.name || user?.displayName}
                  size="small"
                />

                {!isCollapsed && (
                  <div className={style.description()}>
                    <span className={style.name()}>{user?.displayName}</span>

                    <span className={style.role()}>{roleTranslated}</span>
                  </div>
                )}
              </div>
            </li>
          )}

          <li>
            <Tooltip message={t('config')} hideTooltip={!isCollapsed}>
              <Link
                className={style.link({
                  active: location.pathname.includes('/settings/'),
                })}
                to={links.settings.profile}
              >
                <Settings
                  size={18}
                  className={style.icon({
                    isActive: location.pathname.includes('/settings/'),
                  })}
                />
                {!isCollapsed && <span>{t('config')}</span>}
              </Link>
            </Tooltip>
          </li>

          <li>
            <Tooltip message={t('logout')} hideTooltip={!isCollapsed}>
              <button
                type="button"
                className={style.link({ danger: true })}
                onClick={signOut}
              >
                <LogOut size={18} className="text-driver-centricity-800" />
                {!isCollapsed && <span>{t('logout')}</span>}
              </button>
            </Tooltip>
          </li>
        </ul>
      </nav>
    </div>
  );
}
