import { cva } from 'class-variance-authority';

export const menuItem = cva(['hs-accordion', 'cursor-pointer']);

export const accordionToggle = cva([
  'hs-accordion-toggle',
  'flex',
  'items-center',
  'justify-between',
  'gap-x-3.5',
  'py-2',
  'px-2.5',
  'w-full',
  'text-sm',
  'text-neutral-700',
  'rounded-md',
  'hover:bg-brand-50',
  'hover:text-brand-400',
]);

export const wrapper = cva(['flex', 'w-full', 'items-center', 'gap-4']);

export const wrapperMenuChildren = cva(
  [
    'hs-accordion-content',
    'w-full',
    'overflow-hidden',
    'transition-[height]',
    'duration-300',
    'hidden',
  ],
  {
    variants: {
      isCollapsed: {
        true: ['transition-all', 'duration-300', 'transform', 'h-0', 'w-0'],
      },
    },
  },
);

export const menuChildrenItem = cva(['first:mt-3']);

export const menuChildrenRow = cva(['flex', 'items-center', 'pl-6', 'gap-2']);

export const arrowUp = cva(
  ['hidden', 'hs-accordion-active:block', 'text-brand-pure'],
  {
    variants: {
      isCollapsed: {
        true: [
          'hs-accordion-active:hidden',
          'transition-all',
          'duration-300',
          'transform',
        ],
      },
    },
  },
);

export const arrowDown = cva(
  [
    'hs-accordion-active:hidden',
    'text-brand-pure',
    'transition-all ease-in-out delay-150 duration-300',
  ],
  {
    variants: {
      isCollapsed: {
        true: [
          'transition-all',
          'duration-300',
          'opacity-0',
          'w-0',
          'invisible',
        ],
      },
    },
  },
);

export const verticalDivider = cva([
  'w-[2px]',
  'h-9',
  'border-r-2',
  'border-brand-100',
]);

export const icon = cva([''], {
  variants: {
    isActive: {
      true: ['text-white'],
      false: ['text-brand-pure'],
    },
    noAccess: {
      true: ['text-neutral-400'],
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

export const label = cva(['transition-all ease-in-out duration-300'], {
  variants: {
    isCollapsed: {
      true: ['transition-all', 'duration-100', 'hidden'],
    },
    isExternal: {
      true: ['underline'],
    },
    noAccess: {
      true: ['text-neutral-400'],
    },
  },
});

export const link = cva(
  [
    'flex',
    'items-center',
    'gap-x-3.5',
    'p-3.5',
    'text-sm',
    'font-inter',
    'rounded-[4px]',
    'w-full',
    'h-full',
    'truncate',
  ],
  {
    variants: {
      active: {
        true: [
          'bg-gradient-to-b from-inovation-800 from-[-50%] to-brand-400 to-100%',
          'text-brand-50',
        ],
        false: [
          'text-neutral-700',
          'hover:bg-brand-50',
          'hover:text-brand-400',
        ],
      },
      danger: {
        true: [
          'hover:bg-driver-centricity-50',
          'hover:text-driver-centricity-600',
        ],
      },
      noAccess: {
        true: ['cursor-not-allowed', 'hover:bg-transparent'],
      },
    },
    defaultVariants: {
      active: false,
    },
  },
);

export const tooltipWrapper = cva([], {
  variants: {
    isCollapsed: {
      false: ['hidden'],
    },
  },
});
