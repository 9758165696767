import { cva } from 'class-variance-authority';

export const checkbox = cva([
  'shrink-0',
  'mt-0.5',
  'border-2',
  'rounded',
  'text-blue-600',
  'cursor-pointer',
  'focus:ring-blue-500',
]);

export const label = cva([
  'text-sm text-neutral-700 ml-[18px] cursor-pointer font-light',
]);
