import { useTranslation } from 'react-i18next';

import { links } from '@/common/utils/links';

import { ISettingsNavigation } from '../types/SettingsNavigation';

export const SettingsNavigation = (): ISettingsNavigation[] => {
  const { t } = useTranslation('settings/menus');

  return [
    {
      key: 'profile',
      title: t('myProfile'),
      link: links.settings.profile,
    },
    {
      key: 'account',
      title: t('password'),
      link: links.settings.account,
    },
    {
      key: 'company',
      title: t('company'),
      link: links.settings.company,
      flag: 'sidebar_company_menu',
    },
    {
      key: 'modules',
      title: t('modules'),
      link: links.settings.modules,
      flag: 'sidebar_modules_menu',
    },
    {
      key: 'users',
      title: t('users'),
      link: links.settings.users,
      flag: 'sidebar_user_menu',
    },
    {
      key: 'api',
      title: t('apiData'),
      link: links.settings.apiData,
      flag: 'sidebar_api_data_menu',
    },
  ];
};
