import { create } from 'zustand';

import { UserRoleEnum } from '@/common/types/Role';

interface IFiltersUsersTableStore {
  freeSearch: string;
  roles?: UserRoleEnum[];
  updateFreeSearch: (freeSearch: string) => void;
  updateRoles: (roles?: UserRoleEnum[]) => void;
  clearFilters: () => void;
  setFilters: (freeSearch?: string, roles?: UserRoleEnum[]) => void;
}

export const useFiltersUsersTable = create<IFiltersUsersTableStore>(set => {
  return {
    freeSearch: '',
    roles: undefined,
    updateFreeSearch(freeSearch = '') {
      set({ freeSearch });
    },
    updateRoles(roles = undefined) {
      set({ roles });
    },
    clearFilters() {
      set({ freeSearch: '', roles: undefined });
    },
    setFilters(freeSearch = '', roles = undefined) {
      set({ freeSearch, roles });
    },
  };
});
