import { lazy } from 'react';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import { SettingsRoutes } from '@/common/modules/settings/index.routes';
import { Root } from '@/layouts/Root';

import { ClientsRoutes } from '../modules/clients/index.routes';
import { PanelRoutes } from '../modules/panel/index.routes';

const PrivacyPolicy = lazy(() => import('@/common/components/PrivacyPolicy'));
const NotFoundComponent = lazy(() => import('@/common/components/404'));

const BackofficeRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Root />,
    children: [...PanelRoutes, ...ClientsRoutes, ...SettingsRoutes],
    // TODO: Create ErrorBoundaryApi component
    // errorElement: <ErrorBoundaryApi />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '*',
    element: <NotFoundComponent />,
  },
];

export const BackofficeApp = createBrowserRouter(BackofficeRoutes);
