import { ENV } from '@/config/env';

export const links = {
  clients: {
    list: '/clients',
  },
  settings: {
    profile: '/settings/profile',
    account: '/settings/account',
    company: '/settings/company',
    users: '/settings/users',
    modules: '/settings/modules',
    apiData: '/settings/api-data',
  },
  login: {
    main: '/',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    activateAccount: '/activate-account',
  },
  privacyPolicy: '/privacy-policy',
  termsOfUse: ENV.VITE_TERMS_OF_USE_URL,
};
