import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

const Clients = lazy(() => import('./pages/clients'));

export const ClientsRoutes: RouteObject[] = [
  {
    path: links.clients.list,
    element: <Clients />,
  },
];
