import { GetTokenCredentials } from '@/apps/login/hooks/useLogin';
import { httpClient } from '@/config/httpClient';

export interface ISignInResponse {
  id_token: string;
  scope: string;
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  session_state: string;
}

export function signInService({ reCaptcha, ...payload }: GetTokenCredentials) {
  return httpClient.post<ISignInResponse>('/oauth/token', payload, {
    headers: {
      recaptcha: reCaptcha,
    },
  });
}
