import { FileCode2, Home, Users } from 'lucide-react';

import { IAppNavigation } from '@/common/types/AppNavigation';
import { links } from '@/common/utils/links';
import { ENV } from '@/config/env';

export const BackofficeAppNavigation = (): IAppNavigation[] => {
  return [
    {
      key: 'panel',
      title: 'sidebar/backofficeMenus:panel',
      icon: Home,
      link: '/',
    },
    {
      key: 'clientes',
      title: 'sidebar/backofficeMenus:clients',
      icon: Users,
      link: links.clients.list,
      flag: 'sidebar_clients_menu',
    },
    {
      key: 'api',
      title: 'sidebar/backofficeMenus:apiDoc',
      icon: FileCode2,
      external: true,
      link: ENV.VITE_API_DOCS_URL,
    },
  ];
};
