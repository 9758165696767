import { useFlagsmith } from 'flagsmith/react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Tooltip } from '@/common/components/Tooltip';
import { IAppNavigation } from '@/common/types/AppNavigation';

import * as style from './styles';

type NavItemProps = {
  data: IAppNavigation;
  isCollapsed: boolean;
  onClickSubMenu?: () => void;
};

export function NavItem({ data, isCollapsed, onClickSubMenu }: NavItemProps) {
  const { t } = useTranslation(['sidebar/backofficeMenus']);

  const { hasFeature } = useFlagsmith();

  const location = useLocation();

  const { icon: Icon } = data;

  const hasChildren = data?.children?.length;
  const isActive = location.pathname === data?.link;
  const isExternal = data?.external;

  if (data.flag && !hasFeature(data.flag)) return null;

  return (
    <li className={style.menuItem()} key={data.key}>
      {!hasChildren && (
        <Tooltip hideTooltip={!isCollapsed} message={t(data.title)}>
          <Link
            className={style.link({
              active: isActive,
            })}
            to={data.link!}
            target={isExternal ? '_blank' : '_self'}
          >
            {Icon && (
              <Icon
                className={style.icon({
                  isActive,
                })}
                size={18}
              />
            )}

            <span
              className={style.label({
                isCollapsed,
                isExternal,
              })}
            >
              {t(data.title!)}
            </span>
          </Link>
        </Tooltip>
      )}

      {hasChildren && (
        <button
          type="button"
          className={style.accordionToggle()}
          onClick={onClickSubMenu}
        >
          <div className={style.wrapper()}>
            {Icon && (
              <Icon
                className={style.icon({
                  isActive,
                })}
                size={18}
              />
            )}

            <span className={style.label({ isCollapsed })}>{data?.title}</span>
          </div>

          <ChevronUp className={style.arrowUp({ isCollapsed })} size={12} />

          <ChevronDown className={style.arrowDown({ isCollapsed })} size={12} />
        </button>
      )}

      <div className={style.wrapperMenuChildren({ isCollapsed })}>
        <ul>
          {data?.children?.map(menuChildren => {
            return (
              <li key={menuChildren.key} className={style.menuChildrenItem()}>
                <div className={style.menuChildrenRow()}>
                  <div className={style.verticalDivider()} />

                  <Link
                    className={style.link({
                      active: location.pathname === menuChildren.link,
                    })}
                    to={menuChildren.link!}
                  >
                    {menuChildren.title!}
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </li>
  );
}
