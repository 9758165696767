import { cva } from 'class-variance-authority';

export const container = cva([
  'min-w-screen',
  'h-screen',
  'overflow-y-auto',
  'bg-neutral-50',
  'flex',
]);

export const content = cva(['w-full', 'h-screen', 'overflow-y-auto', 'p-8']);
