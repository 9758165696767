import { z } from 'zod';

const nodeEnv = z.enum(['development', 'production']);

function requiredOnEnv(env: z.infer<typeof nodeEnv>) {
  return (value: unknown) => {
    if (env === process.env.NODE_ENV && !value) {
      return false;
    }

    return true;
  };
}

const envSchema = z.object({
  MODE: nodeEnv,
  VITE_API_URL: z.string().nonempty(),
  VITE_API_DOCS_URL: z.string().nonempty().refine(requiredOnEnv('production')),
  VITE_GOOGLE_RECAPTCHA_SITE_KEY: z
    .string()
    .nonempty()
    .refine(requiredOnEnv('production')),
  VITE_FLAGSMITH_ENV_ID: z.string().nonempty(),
  VITE_TERMS_OF_USE_URL: z.string().nonempty(),
});

export const ENV = envSchema.parse(import.meta.env);
