import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

import { links } from '@/common/utils/links';

import { Settings } from './layouts/settings';

const Account = lazy(() => import('./pages/account'));
const ApiData = lazy(() => import('./pages/apiData'));
const Company = lazy(() => import('./pages/company'));
const Modules = lazy(() => import('./pages/modules'));
const Profile = lazy(() => import('./pages/profile'));
const User = lazy(() => import('./pages/user'));

export const SettingsRoutes: RouteObject[] = [
  {
    element: <Settings />,
    children: [
      {
        path: links.settings.profile,
        element: <Profile />,
      },
      {
        path: links.settings.account,
        element: <Account />,
      },
      {
        path: links.settings.company,
        element: <Company />,
      },
      {
        path: links.settings.modules,
        element: <Modules />,
      },
      {
        path: links.settings.users,
        element: <User />,
      },
      {
        path: links.settings.apiData,
        element: <ApiData />,
      },
    ],
  },
];
