import { cva } from 'class-variance-authority';

export const container = cva([
  'select-none',
  'font-inter',
  'w-full',
  'pb-5',
  'flex',
  'flex-col',
  'items-start',
  'gap-2',
]);

export const heading = cva(['flex', 'gap-1', 'w-full', 'justify-between']);

export const title = cva(['text-3xl', 'font-semibold', 'text-brand-800']);

export const headerFooter = cva([
  'flex',
  'gap-2',
  'items-center',
  'justify-between',
  'w-full',
]);

export const breadCrumbs = cva(['flex', 'gap-5', 'items-center', 'w-full']);

export const actions = cva(['flex', 'justify-center', 'items-center', 'gap-4']);

export const actionsWrapper = cva(['flex', 'gap-4', 'items-end']);

export const badgesContainer = cva(['flex', 'justify-end', 'gap-4', 'w-full']);
