import { create } from 'zustand';

import { IOwnerUser } from '../types/OwnerUser';
import { UserRoleEnum } from '../types/Role';
import { IGetUserInfo } from '../types/User';

const UserAccountArray: Pick<IGetUserInfo, 'UserAccount'>['UserAccount'] = [];
type UserAccount = typeof UserAccountArray[0];

const UserCompanyArray: Pick<IGetUserInfo, 'UserCompany'>['UserCompany'] = [];
type UserCompany = typeof UserCompanyArray[0]['Company'];

interface IUserStore {
  user: IGetUserInfo | null;
  person: Pick<IGetUserInfo, 'Person'>['Person'] | null;
  personId: Pick<IGetUserInfo, 'personId'>['personId'] | null;
  account: UserAccount | null;
  accountId: string | null;
  company: UserCompany | null;
  companyId: string | null;
  ownerId: string | null;
  owner: IOwnerUser | null;
  role: UserRoleEnum | null;
  storeAccountUser: (user: IGetUserInfo) => void;
  storeCompanyUser: (user: IGetUserInfo) => void;
}

export const useUserStore = create<IUserStore>(set => {
  const storeAccountUser = (user: IGetUserInfo) => {
    if (user && user?.UserAccount && user?.UserAccount.length) {
      set({
        user,
        person: user?.Person,
        personId: user?.personId,
        role: user?.UserAccount[0]?.role as UserRoleEnum,
        account: user?.UserAccount[0],
        accountId: user?.UserAccount[0].accountId,
        company: null,
        companyId: null,
      });
    }
  };

  const storeCompanyUser = (user: IGetUserInfo) => {
    if (user && user?.UserCompany && user?.UserCompany.length) {
      set({
        user,
        person: user?.Person,
        personId: user?.personId,
        role: user?.UserCompany[0]?.role as UserRoleEnum,
        account: null,
        accountId: user?.UserCompany[0].accountId,
        company: user?.UserCompany[0].Company,
        companyId: user?.UserCompany[0].companyId,
        ownerId: user?.UserCompany[0].Company?.ownerId,
        owner: user?.UserCompany[0].Company?.User,
      });
    }
  };

  return {
    user: null,
    person: null,
    personId: null,
    role: null,
    account: null,
    accountId: null,
    company: null,
    companyId: null,
    ownerId: null,
    owner: null,
    storeAccountUser,
    storeCompanyUser,
  };
});
