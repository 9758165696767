import { IGoogleRecaptchaInstance } from '@/@types/GoogleRecaptcha';
import { zodResolver } from '@hookform/resolvers/zod';
import flagsmith from 'flagsmith';
import { useFlags } from 'flagsmith/react';
import { Eye, EyeOff, Lock } from 'lucide-react';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import NexproLogoImg from '@/assets/icons/nexpro.svg';
import RecaptchaIcon from '@/assets/icons/recaptcha.svg';
import Button from '@/common/components/Button';
import { Checkbox } from '@/common/components/Checkbox';
import { TextInput } from '@/common/components/TextInput';
import { EnumAPPS, useAppStore } from '@/common/hooks/AppStore';
import { useUserStore } from '@/common/hooks/UserStore';
import { useToast } from '@/common/hooks/useToast';
import { getUserInfo } from '@/common/services/user';
import { IAxiosError } from '@/common/types/AxiosError';
import { setItemLocal } from '@/common/utils/localStorage';
import { ENV } from '@/config/env';

import { SignInForm, useLogin } from '../../hooks/useLogin';

import * as style from './styles';

export function SignIn() {
  const [loadingCaptchaToken, setLoadingCaptchaToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { toast } = useToast();
  const isAllowSignIn = useFlags(['allow_sign_in']);

  const { t } = useTranslation(['common', 'signIn/login']);
  const { SignInSchema, signIn, isLoadingSignIn } = useLogin();
  const { storeAccountUser, storeCompanyUser } = useUserStore();

  const { changeApp } = useAppStore();

  const hookForm = useForm<SignInForm>({
    mode: 'onBlur',
    resolver: zodResolver(SignInSchema),
  });

  const captchaRef = useRef<IGoogleRecaptchaInstance>(null);

  const verifyCaptcha = async () => {
    try {
      if (!captchaRef?.current) {
        toast.warn(t('signIn/login:reCaptchaHumanError'));
        return null;
      }

      setLoadingCaptchaToken(true);
      captchaRef?.current?.reset();

      const reCaptchaToken = await captchaRef?.current?.executeAsync();

      return reCaptchaToken;
    } catch (error) {
      toast.error(t('signIn/login:reCaptchaAPIError'));
      return null;
    } finally {
      setLoadingCaptchaToken(false);
    }
  };

  const requestSignIn = async (data: SignInForm, reCaptchaToken: string) => {
    try {
      await signIn({ ...data, reCaptchaToken });
      const response = await getUserInfo();

      const user = response.data;

      if (!user) {
        toast.error(t('signIn/login:signInError'));
        return;
      }

      await flagsmith.identify(user?.email);

      if (user?.UserAccount) {
        storeAccountUser(user);
        await flagsmith.setTraits({
          userType: user.userType,
          role: user?.UserAccount[0].role,
        });
      }

      if (user?.UserCompany) {
        storeCompanyUser(user);
        await flagsmith.setTraits({
          userType: user.userType,
          role: user?.UserCompany[0].role,
        });
      }

      await flagsmith.getFlags();
      flagsmith.startListening(1000 * 60 * 5);

      changeApp(EnumAPPS.API);

      setItemLocal('app', EnumAPPS.API);
    } catch (error: unknown) {
      const requestError = error as IAxiosError;

      toast.error(
        requestError?.response?.data?.friendlyMessage ||
          t('signIn/login:signInError'),
      );
    }
  };

  const handleSignIn = async (data: SignInForm) => {
    const reCaptchaToken = await verifyCaptcha();

    if (!reCaptchaToken) {
      hookForm.setError('reCaptcha', {
        message: 'reCaptcha error',
      });
      return;
    }

    await requestSignIn(data, reCaptchaToken);
  };

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <main className={style.main()}>
        <header className={style.header()}>
          <img src={NexproLogoImg} alt="IVECO" className={style.logo()} />

          <h2 className={style.subTitle()}>{t('signIn/login:subTitle')}</h2>
        </header>

        <FormProvider {...hookForm}>
          <form
            className={style.form()}
            onSubmit={hookForm.handleSubmit(handleSignIn)}
          >
            <TextInput
              size="lg"
              name="username"
              placeholder={t('signIn/login:username')}
            />

            <TextInput
              size="lg"
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={t('signIn/login:password')}
              TrailingIcon={
                showPassword ? (
                  <Eye className="text-neutral-500" size={16} />
                ) : (
                  <EyeOff className="text-neutral-500" size={16} />
                )
              }
              handleClickTrailingIcon={handleToggleShowPassword}
            />

            <fieldset className={style.options()}>
              <Checkbox
                name="rememberData"
                label={t('signIn/login:rememberMe')}
                extraClassNames={style.checkbox()}
              />

              <div className={style.divider()} />

              <div className={style.wrapperLock()}>
                <Lock size={14} className={style.lock()} strokeWidth={3} />

                <Link to="/forgot-password" className={style.forgotPassword()}>
                  {t('signIn/login:forgotPassword')}
                </Link>
              </div>
            </fieldset>

            <fieldset className={style.reCaptchaContainer()}>
              <div
                className={style.reCaptcha({
                  hasError: !!hookForm.formState.errors.reCaptcha,
                })}
              >
                <Checkbox name="reCaptcha" label="Não sou um robô" />

                <img src={RecaptchaIcon} alt="reCAPTCHA" />
              </div>

              {hookForm.formState.errors.reCaptcha && (
                <p className={style.reCaptchaError()}>
                  {t('signIn/login:reCaptchaError')}.
                </p>
              )}
            </fieldset>

            <Button
              type="submit"
              isLoading={isLoadingSignIn || loadingCaptchaToken}
              text={t('signIn/login:signIn')}
              disabled={!isAllowSignIn.allow_sign_in.enabled}
              className={style.button()}
            />
          </form>
        </FormProvider>

        <footer className={style.footer()}>
          <a
            href={ENV.VITE_TERMS_OF_USE_URL}
            target="_bank"
            className={style.privacyPolicy()}
          >
            {t('common:termsOfUseAndPrivacyPolicy')}
          </a>

          <p className={style.copyright()}>
            &copy; Copyright By IVECO - {t('signIn/login:allRightsReserved')}
          </p>
        </footer>
      </main>

      <ReCAPTCHA
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={captchaRef}
        sitekey={ENV.VITE_GOOGLE_RECAPTCHA_SITE_KEY}
        size="invisible"
        className="hidden"
      />
    </>
  );
}
